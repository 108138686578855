import { createContext, useContext } from 'react';
import {
  ActionType,
  ReducerAction,
  addToCart as addToCartAction,
  removeFromCart as removeFromCartAction,
  clearCart as clearCartAction,
} from './actions';
import { CartProps, CartItemProps } from '../../types/types'
import { getMenuItem } from '../helpers/utils';
import { INDIVIDUAL_MENU } from '../constants'

export interface ReducerState {
  cart?: CartProps;
}

export interface ReducerContextConsumer {
  state?: ReducerState;
  dispatch?: (action: ReducerAction) => void;
}

export const ReducerContext = createContext<ReducerContextConsumer>({});

const DEFAULT_CART: CartProps = {
  items: [],
  total: 0,
}

export function useAppCart(): CartProps {
  const { state } = useContext(ReducerContext);

  return state?.cart ?? DEFAULT_CART;
}

export function useCartDispatchers() {
  const { dispatch } = useContext<ReducerContextConsumer>(ReducerContext);

  const dispatchAddToCart = (cartItem: CartItemProps): void => {
    if (dispatch) {
      dispatch(addToCartAction(cartItem));
    }
  };

  const dispatchRemoveFromCart = (cartItem: CartItemProps): void => {
    if (dispatch) {
      dispatch(removeFromCartAction(cartItem));
    }
  };

  const dispatchClearCart = (): void => {
    if (dispatch) {
      dispatch(clearCartAction());
    }
  }

  return { dispatchAddToCart, dispatchRemoveFromCart, dispatchClearCart };
}

function addToCart(cartItem: CartItemProps, cart?: CartProps): CartProps {
  const menuItem = getMenuItem(cartItem) ?? INDIVIDUAL_MENU[0];
  const itemTotal = menuItem.price * cartItem.quantity;

  if (!cart) {
    return {
      items: [cartItem],
      total: itemTotal
    };
  }

  const currentCartItem = cart.items.find((item) => item.id === cartItem.id);
  const cartTotal = cart.total + itemTotal;

  return !currentCartItem
    ? { items: [...cart.items, cartItem], total: cartTotal }
    :  {
      items: [...cart.items, {...currentCartItem, quantity: (currentCartItem.quantity + cartItem.quantity)}],
      total: cartTotal
    };
}

function removeFromCart(cartItem: CartItemProps, cart?: CartProps): CartProps | undefined {
  const menuItem = getMenuItem(cartItem) ?? INDIVIDUAL_MENU[0];
  const itemTotal = menuItem.price * cartItem.quantity;

  if (!cart) {
    return undefined;
  }

  const cartTotal = cart.total - itemTotal;
  const cartItems = cart.items.filter((item) => (item.id !== cartItem.id));

  return {
      items: cartItems,
      total: cartTotal
    };
}

function reducer(state: ReducerState, action: ReducerAction): ReducerState {
  switch (action.type) {
    case ActionType.ADD_TO_CART: {
      return { ...state, cart: addToCart(action.payload, state.cart) };
    }

    case ActionType.REMOVE_FROM_CART: {
      return { ...state, cart: removeFromCart(action.payload, state.cart) };
    }

    case ActionType.CLEAR: {
      return { ...state, cart: {items: [], total: 0}};
    }
  }
}

export default reducer;
