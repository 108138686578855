import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '../icons/Delete';
import { CartItemProps, MenuItemProps, StyleObject } from '../../../types/types';
import { formatMoney } from '../../helpers/utils';
import { useCartDispatchers } from '../../reducers';

const STYLES: StyleObject = {
  title: {marginBottom: '-10px'},
  description: {marginBottom: '-5px'},
  total: {textAlign: 'end'},
  button: {
    marginTop: '3px',
    padding: 0,
  }
}

function getDescription(menuItem: MenuItemProps, cartDetails: CartItemProps, isExtended: boolean): string {
  return isExtended
    ? `Cantidad ${cartDetails.quantity} × Precio ${menuItem.price/100}`
    : `${cartDetails.quantity} × ${menuItem.price/100}`
}

interface ItemProps {
  menuItem: MenuItemProps | undefined;
  cartDetails: CartItemProps;
  isExtended?: boolean;
  addRemoveLink?: boolean;
}

export default function CartMenuItem(props: ItemProps): JSX.Element {
  const { menuItem, cartDetails, isExtended = false, addRemoveLink } = props;
  const { dispatchRemoveFromCart } = useCartDispatchers();

  if (!menuItem) {
    return <></>;
  }

  const priceDescription = getDescription(menuItem, cartDetails, isExtended);
  const total = cartDetails.quantity * menuItem.price;

  const handleRemoveClick = ():void => {
    dispatchRemoveFromCart(cartDetails);
  };

  return (
    <React.Fragment>
      <Grid item xs={8}>
        <Typography gutterBottom variant="h6" style={STYLES.title}>
          {menuItem.title}
        </Typography>
        <Typography gutterBottom variant="subtitle1" style={STYLES.description}>
          {priceDescription}
        </Typography>
      </Grid>
      <Grid item xs={!addRemoveLink ? 4 : 3}>
        <Typography gutterBottom variant="h6" style={{...STYLES.total, ...STYLES.title}}>
          {formatMoney(total)}
        </Typography>
      </Grid>
      {addRemoveLink && (
        <Grid item xs={1}>
          <IconButton aria-label="delete" color="secondary" style={STYLES.button} onClick={handleRemoveClick}>
            <DeleteIcon fontSize="default" />
          </IconButton>
        </Grid>
      )}
    </React.Fragment>
  );
}
