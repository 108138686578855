import { useEffect } from 'react';
import { Screen, SetOpenScreen } from '../../types/types';

function getScreenHashFromLocation() {
  return document.location.hash.replace('#', '');
}

function navigateTo(setOpenScreen: SetOpenScreen, screen: string | Screen) {
  switch (screen) {
    case Screen.Checkout:
      setOpenScreen(Screen.Checkout);
      return;

    case Screen.ComboMenu:
      setOpenScreen(Screen.ComboMenu);
      return;

    case Screen.IndividualMenu:
      setOpenScreen(Screen.IndividualMenu);
      return;

    case Screen.Home:
    default:
      setOpenScreen(Screen.Home);
      return;
  }
}

export function useOnPopStateEffect(setOpenScreen: SetOpenScreen, setIsAppMenuOpen: (openScreen: boolean) => void) {
  useEffect(() => {
    window.onpopstate = function() {
      const isAppMenuOpen = window.localStorage.getItem('isAppMenuOpen') === 'true';

      if (isAppMenuOpen) {
        window.history.forward();
        setIsAppMenuOpen(false);
        return;
      }

      const screenHash = getScreenHashFromLocation();
      navigateTo(setOpenScreen, screenHash);
    }

    return () => {
      window.onpopstate = null
    };
  }, []);
}

export function useInitialRouteSetupEffect() {
  useEffect(() => {
    window.history.pushState('', '', `./`);
    window.history.pushState(Screen.Home, '', `./#${Screen.Home}`);
  }, []);
}

function registerWindowHistory(screen: Screen) {
  window.history.pushState(screen, '', `./#${screen}`);
  window.scrollTo({top: 0});
}

export function navigateToHome(setOpenScreen: SetOpenScreen): void {
  navigateTo(setOpenScreen, Screen.Home);
  registerWindowHistory(Screen.Home);
};

export function navigateToIndividualMenu(setOpenScreen: SetOpenScreen): void {
  navigateTo(setOpenScreen, Screen.IndividualMenu);
  registerWindowHistory(Screen.IndividualMenu);
};

export function navigateToComboMenu(setOpenScreen: SetOpenScreen): void {
  navigateTo(setOpenScreen, Screen.ComboMenu);
  registerWindowHistory(Screen.ComboMenu);
};

export function navigateToCheckout(setOpenScreen: SetOpenScreen): void {
  navigateTo(setOpenScreen, Screen.Checkout);
  registerWindowHistory(Screen.Checkout);
};
