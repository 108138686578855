import { Link } from 'gatsby';
import React, {useState, useEffect} from 'react';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import { useAppCart } from '../../reducers';
import MenuIcon from '../icons/Menu';
import CloseIcon from '../icons/Close';
import {StyleObject} from '../../../types/types'
import useAppMenu from '../../hooks/useAppMenu';
import useOpenScreen from '../../hooks/useOpenScreen';
import { navigateToHome } from '../../helpers/navigation';

interface Props {
  siteTitle: string;
}

const STYLES: StyleObject = {
  container: {
    backgroundColor: '#723f5e',
    marginBottom: `1.45rem`,
    position: 'fixed',
    top: 0,
    width: '100%',
    overflow: 'hidden',
    zIndex: 5,
  },
  textContent: {
    margin: `0 auto`,
    maxWidth: '1025px',
    padding: `1.45rem 1.0875rem`,
    display:'flex',
  },
  title: {
    margin: 0,
    alignSelf: 'center',
  },
  icon: {
    float: 'right',
    color: 'white',
    marginLeft: 'auto',
  },
  link: {
    color: `white`,
    textDecoration: `none`,
  },
  badge: {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    backgroundColor: 'blue',
    width: '20px',
    height: '20px',
    fontSize: '10px',
    justifyContent: 'center',
  },
}


function getMenuIcon(isMenuOpen: boolean): JSX.Element {
  return isMenuOpen
    ? (<CloseIcon fontSize="large" />)
    : (<MenuIcon fontSize="large" />);
}

export default function Header ({ siteTitle }: Props): JSX.Element {
  const cart = useAppCart();
  const totalCartItems = cart.items.reduce((acc, item) => acc + item.quantity, 0);
  const [invisible, setInvisible] = useState(true);
  const { setOpenScreen } = useOpenScreen();

  useEffect(() => {
    setInvisible(totalCartItems === 0 ? true : false);
  }, [totalCartItems]);

  const {isAppMenuOpen, setIsAppMenuOpen} = useAppMenu();

  const handleMenuIconClick = (): void => {
    setIsAppMenuOpen(!isAppMenuOpen);
  }

  const handleGoHomeClick = () => {
    navigateToHome(setOpenScreen);
  }

  return (
    <header style={STYLES.container}>
      <div style={STYLES.textContent}>
        <h1 style={STYLES.title}>
          <Link to="/" onClick={handleGoHomeClick} style={STYLES.link}>
            {siteTitle}
          </Link>
        </h1>
        <IconButton
          style={STYLES.icon}
          aria-label="add to favorites"
          onClick={handleMenuIconClick}
        >
          <Badge
            invisible={invisible}
            badgeContent={totalCartItems}
            color="error"
            overlap="circle"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            {getMenuIcon(isAppMenuOpen)}
          </Badge>
        </IconButton>
      </div>
    </header>
  );
}
