import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../Header"
import AppDrawer from "../AppDrawer"
import { StyleObject } from "../../../types/types"

const STYLES: StyleObject = {
  container: {
    fontFamily: 'Copperplate',
    position: 'relative',
    minHeight: '85vh',
  },
  content: {
    margin: '0 auto',
    maxWidth: '1025px',
    paddingTop: '130px',
  },
  footer: {
    marginTop: `2rem`,
    padding: '0px 1.0875rem 0.45rem',

    position: 'absolute',
    bottom: '-50px',
    // width: '960px',
  // height: 2.5rem;
  },
}

interface LayoutProps {
  children: React.ReactNode
}

export default function Layout({ children }: LayoutProps): JSX.Element {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div style={STYLES.container}>
      <Header siteTitle={data.site.siteMetadata?.title || `Antojitos Venezolanos`} />
      <AppDrawer />
      <div style={STYLES.content}>
        <main>{children}</main>
        <footer style={STYLES.footer}>
          © 2020, Built with Gatsby <a href="">Antojitos Venezolano</a>
        </footer>
      </div>
    </div>
  )
}
