import { createContext, useContext } from 'react';
import { Screen, SetOpenScreen } from '../../types/types';

interface OpenScreenContextProps {
  openScreen: Screen;
  setOpenScreen: SetOpenScreen;
}

export const OpenScreenContext = createContext<OpenScreenContextProps>({
  openScreen: Screen.IndividualMenu,
  setOpenScreen: (screen: Screen) => screen
});

export default function useOpenScreen(): OpenScreenContextProps {
  return useContext(OpenScreenContext)
}