import { createContext, useContext } from 'react';

export type SetMenuOpen = (isAppMenuOpen: boolean) => void;

interface AppMenuContextProps {
  isAppMenuOpen: boolean;
  setIsAppMenuOpen: SetMenuOpen;
}

export const AppMenuContext = createContext<AppMenuContextProps>({
  isAppMenuOpen: false,
  setIsAppMenuOpen: (isAppMenuOpen: boolean) => isAppMenuOpen
});

export default function useAppMenu(): AppMenuContextProps {
  return useContext(AppMenuContext)
}