import React from 'react';

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { StyleObject } from '../../../types/types';

const STYLES: StyleObject = {
  selectedOption: {
    backgroundColor: 'white',
    color: 'black',
  }
}

interface Props {
  title: string;
  icon: JSX.Element;
  onClick: () => void;
  isSelected?: boolean
}

export default function DrawerOption(props: Props): JSX.Element {
  const styles = props.isSelected ? STYLES.selectedOption : {};

  return (
    <ListItem button onClick={props.onClick} style={styles}>
      <ListItemIcon>
        {props.icon}
      </ListItemIcon>
      <span>{props.title}</span>
    </ListItem>
  );
}
