import {Moment} from 'moment';
import { CartProps, CartItemProps, MenuItemProps } from '../../types/types';
import { PaymentOption } from '../components/CheckoutScreen/sections/CustomerSection';
import { INDIVIDUAL_MENU, COMBO_MENU } from '../constants'

export function formatMoney(value: number): string {
  return `B/. ${value/100}`;
}

export function getMenuItem(cart: CartItemProps): MenuItemProps | undefined {
  return [...INDIVIDUAL_MENU, ...COMBO_MENU].find((item) => item.id === cart.id);
}

export function getWhatsAppMessage(
  cart: CartProps,
  name: string,
  lastname: string,
  optionalPhone: string,
  isDelivery: boolean,
  possibleDate: Moment,
  paymentOption: PaymentOption,
): string {
  const encodedMenuItems = cart.items.map((item) => {
    const cartItem = getMenuItem(item);

    return encodeURIComponent(`Item: ${cartItem?.title}, Cantidad: ${item.quantity}`);
  }).join('%0A');

  const delivery = `Tipo de Entrega: ${isDelivery ? 'A Domicilio' : 'Recoger'}`

  return [
    'Pedido',
    encodedMenuItems,
    'Informacion de Contacto:',
    encodeURIComponent(`Nombre: ${name} ${lastname}, Otro Telefono: ${optionalPhone}`),
    encodeURIComponent(delivery),
    encodeURIComponent(`Fecha Tentativa: ${possibleDate.format('DD-MM-YYYY')}`),
    encodeURIComponent(`Forma de Pago: ${paymentOption}`)
  ].join('%0A')
}