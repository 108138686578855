import CSS from 'csstype';

export interface StyleObject {
  [key: string]: CSS.Properties
}

export enum MenuItemId {
  BreadHam = 'BreadHam',
  EmptyCart = 'EmptyCart',
  EmptyCart2 = 'EmptyCart2',
  ComboA = 'ComboA',
  ComboB = 'ComboB',
  ComboC = 'ComboC',
  ComboD = 'ComboD',
  Hallacas = 'Hallacas',
  HallacasBun = 'HallacasBun',
  PorkRoast = 'PorkRoast',
  SmallPapayaSweet = 'SmallPapayaSweet',
  BigPapayaSweet = 'BigPapayaSweet',
  Salad = 'Salad',
  MenuMain = 'MenuMain',
  ComboMain = 'ComboMain',
  ChristmasDish = 'ChristmasDish',
}

export interface MenuItemProps {
  id: MenuItemId;
  title: string;
  description: string;
  price: number;
}

export interface CartItemProps {
  id: MenuItemId;
  quantity: number;
}

export interface CartProps {
  items: CartItemProps[],
  total: number;
}

export enum Screen {
  IndividualMenu = 'IndividualMenu',
  ComboMenu = 'ComboMenu',
  Checkout = 'Checkout',
  Home = 'Home',
}

export type SetOpenScreen = (screen: Screen) => void;