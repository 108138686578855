import { MenuItemId, MenuItemProps } from '../../types/types';

export const INDIVIDUAL_MENU: MenuItemProps[] = [
  {
    id: MenuItemId.Hallacas,
    title: 'Hallacas',
    description: 'Relleno de Cerdo y Pollo, toque caraqueño (dulzón). Pasitas, Aceitunas, Alcaparras, Pimentón, Cebolla',
    price: 425
  }, {
    id: MenuItemId.HallacasBun,
    title: 'Bollitos',
    description: 'Incluye trozitos de Cerdo y Pollo, toque caraqueño (dulzón). Pasitas, Aceitunas, Alcaparras, Pimentón, Cebolla',
    price: 325
  }, //{
    //id: MenuItemId.SmallPapayaSweet,
    //title: 'Dulce Lechoza Pq',
   // description: 'Conserva de Lechoza/papaya. Porcion para 1 persona',
  //  price: 300
  //}, {
   // id: MenuItemId.BigPapayaSweet,
   // title: 'Dulce Lechoza Gd',
   // description: 'Conserva de Lechoza/papaya. Porcion para 4 personas',
   // price: 700
 // },
  {
    id: MenuItemId.PorkRoast,
    title: 'Libra de Pernil',
    description: 'Pernil de Cerdo en su jugo.',
    price: 1200
  }, {
    id: MenuItemId.Salad,
    title: 'Libra de Ensalada',
    description: 'Ensalada a base de zanahorias, papas, pollo, guisantes, manzana verde y aderezo',
    price: 700
  }, {
    id: MenuItemId.BreadHam,
    title: 'Pan de Jamon',
    description: 'Pan de Jamón de 30-35 cms, 1 kilo aprox',
    price: 2200
  }, {
    id: MenuItemId.ChristmasDish,
    title: 'Plato Navideño',
    description: '1 Hallaca, Porción de Pernil, Porción de Ensalada, 1 Porción de Pan de Jamón',
    price: 1200
  },
];

export const COMBO_MENU: MenuItemProps[] = [
  {
   //id: MenuItemId.ChristmasDish,
   // title: 'Combo Plus',
   // description: `Contiene: - 8 hallacas, 1.5 libras de pernil, 1.5 libras de ensalada, 1 pan de jamon de 35 cms`,
   // price: 6500
  //},{
    id: MenuItemId.ComboA,
    title: 'Combo Pequeño',
    description: `Contiene: - 5 Hallacas - 1.5 Libras de Pernil  - 1 Libra de Ensalada`,
    price: 4200
  }, {
    id: MenuItemId.ComboB,
    title: 'Combo Mediano',
    description: `Contiene: - 7 Hallacas - 2 Libras de Pernil  - 2 Libras de Ensalada - 2 Bollitos`,
    price: 7000
  }, {
    id: MenuItemId.ComboC,
    title: 'Combo Grande',
    description: `Contiene: - 10 Hallacas - 3 Libras de Pernil  - 3 Libras de Ensalada - 1 Pan de Jamón (Platos Individuales o en Bandeja)`,
    price: 10500
  },
];
