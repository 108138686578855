import { CartItemProps } from '../../types/types';

export enum ActionType {
  ADD_TO_CART = '@reducer/ADD_TO_CART',
  REMOVE_FROM_CART = '@reducer/REMOVE_FROM_CART',
  CLEAR = '@reducer/CLEAR',
}

interface AddToCardAction {
  type: ActionType.ADD_TO_CART;
  payload: CartItemProps;
}

interface RemoveFromCartAction {
  type: ActionType.REMOVE_FROM_CART;
  payload: CartItemProps;
}

interface ClearAction {
  type: ActionType.CLEAR;
}

export type ReducerAction = AddToCardAction | RemoveFromCartAction | ClearAction;

export const addToCart = (cartItem: CartItemProps): AddToCardAction => ({ type: ActionType.ADD_TO_CART, payload: cartItem });
export const removeFromCart = (cartItem: CartItemProps): RemoveFromCartAction => ({ type: ActionType.REMOVE_FROM_CART, payload: cartItem });
export const clearCart = (): ClearAction => ({ type: ActionType.CLEAR });
