import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { StyleObject, CartItemProps } from '../../../types/types';
import { useAppCart } from '../../reducers';
import { formatMoney, getMenuItem } from '../../helpers/utils';
import CartMenuItem from '../CartMenuItem';

const STYLES: StyleObject = {
  container: {padding: '10px', backgroundColor: 'gray'},
  title: {float: 'right'},
  items: {fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'},
  totalSection: {marginTop: '10px', marginBottom: '10px'},
  total: {textAlign: 'end'},
}

function getCartMenuMapper(item: CartItemProps): JSX.Element {
  return (
    <CartMenuItem
      key={item.id}
      menuItem={getMenuItem(item)}
      cartDetails={item}
    />
  );
}

export default function DrawerCart(): JSX.Element {
  const cart = useAppCart();

  if (cart.items.length === 0) {
    return <></>;
  }

  const total = `Total ${formatMoney(cart.total)}`;

  return (
    <div style={STYLES.container}>
      <div style={STYLES.title}>
        <span>Resumen de Tu Pedido</span>
      </div>
      <Grid container spacing={1} style={STYLES.items}>
        {cart.items.map(getCartMenuMapper)}
      </Grid>
      <div style={STYLES.totalSection}>
        <Typography gutterBottom variant="h6" style={STYLES.total}>
          {total}
        </Typography>
      </div>
    </div>
  );
}
