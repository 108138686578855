import React, { CSSProperties } from 'react';

import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";

import RestaurantMenuIcon from '../icons/RestaurantMenu';
import ArrowBackIosIcon from '../icons/ArrowBackIos';
import MenuBookIcon from '../icons/MenuBook';
import HomeIcon from '../icons/Home';
import ShoppingCart from '../icons/ShoppingCart';

import {StyleObject, Screen} from '../../../types/types'
import useAppMenu from '../../hooks/useAppMenu';
import useOpenScreen from '../../hooks/useOpenScreen';
import {
  navigateToCheckout,
  navigateToComboMenu,
  navigateToHome,
  navigateToIndividualMenu,
} from '../../helpers/navigation';
import DrawerOption from './DrawerOption';
import DrawerCart from './DrawerCart';

const STYLES: StyleObject = {
  container: {
    width: '250px',
    backgroundColor: '#723f5e',
    height: '100%',
    fontFamily: 'Antojitography, Roboto',
    fontSize: 'xx-large',
    color: 'white',
  },
  unselected: {color: 'white'},
  selected: {color: 'black'},
}

function getIconStyles(isSelected: boolean): CSSProperties {
  return isSelected ? STYLES.selected : STYLES.unselected;
}

export default function AppDrawer (): JSX.Element {
  const {isAppMenuOpen, setIsAppMenuOpen} = useAppMenu();
  const {openScreen, setOpenScreen} = useOpenScreen();

  const handleAppMenuClose = (): void => {
    setIsAppMenuOpen(false);
  };

  const handeOpenHome = (): void => {
    navigateToHome(setOpenScreen);
    handleAppMenuClose();
  };

  const handeOpenIndividualMenu = (): void => {
    navigateToIndividualMenu(setOpenScreen);
    handleAppMenuClose();
  };

  const handeOpenComboMenu = (): void => {
    navigateToComboMenu(setOpenScreen);
    handleAppMenuClose();
  };

  const handeOpenCheckout = (): void => {
    navigateToCheckout(setOpenScreen);
    handleAppMenuClose();
  };

  return (
    <Drawer anchor="right" open={isAppMenuOpen} onClose={handleAppMenuClose}>
      <div style={STYLES.container} role="presentation" onClick={()=>{}}>
        <List>
          <DrawerOption
            title="Cerrar"
            onClick={handleAppMenuClose}
            icon={<ArrowBackIosIcon style={{color: 'white'}} />}
          />
        </List>
        <Divider style={{backgroundColor: 'white'}} />
        <List>
          <DrawerOption
            title="Inicio"
            onClick={handeOpenHome}
            icon={<HomeIcon style={getIconStyles(openScreen === Screen.Home)} />}
            isSelected={openScreen === Screen.Home}
          />
          <DrawerOption
            title="Individual"
            onClick={handeOpenIndividualMenu}
            icon={<RestaurantMenuIcon style={getIconStyles(openScreen === Screen.IndividualMenu)} />}
            isSelected={openScreen === Screen.IndividualMenu}
          />
          <DrawerOption
            title="En Combo"
            onClick={handeOpenComboMenu}
            icon={<MenuBookIcon style={getIconStyles(openScreen === Screen.ComboMenu)} />}
            isSelected={openScreen === Screen.ComboMenu}
          />
          <DrawerOption
            title="Tu Carrito"
            onClick={handeOpenCheckout}
            icon={<ShoppingCart style={getIconStyles(openScreen === Screen.Checkout)} />}
            isSelected={openScreen === Screen.Checkout}
          />
        </List>
        <Divider style={{backgroundColor: 'white'}} />
        <DrawerCart />
      </div>
    </Drawer>
  );
}


